import React from "react";
import "./LoadingVideo.css";
const LoadingVideo = () => {
  return (
    <div className="loading-video-container">
      <video autoPlay loop muted>
      <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LoadingVideo;
