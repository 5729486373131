import React, { useContext } from "react";
import ProductManagement from "./ProductManagement";
import AboutPageManagement from "./AboutManagement";
import { AuthContext } from "../../context/AuthContext";
import OrderManagement from "./OrderManagement";
export const Dashboard = () => {
  const { user } = useContext(AuthContext);
  console.log("user",user);
  const ADMIN_EMAIL = [
    "aruns.ug21.cs@nitp.ac.in",
    "satyameviasacademy@gmail.com",
    "themilletodyssey@gmail.com",
    "arunsinghbrh9006@gmail.com",
    "lakshyaparashar0411@gmail.com"
  ];
  const isAdmin = user && ADMIN_EMAIL.includes(user.email);
  console.log("isadmin",isAdmin);
  console.log("user",user);
  if (!isAdmin) {
    return <p>Access Denied</p>;
  }
  return (
    <>
      <div>
        <h1>Dashboard</h1>
        <div>
          <ProductManagement />
        </div>
        <div>
          <AboutPageManagement />
        </div>
        <div>
          <OrderManagement/>
        </div>
      </div>
    </>
  );
};
