import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Dashboard.css";

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({
    name: "",
    orderId: "",
    awsNumber: "",
  });
  const [message, setMessage] = useState(""); // New state for messages

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/mapOrder/`);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setMessage("Failed to fetch orders. Please try again later.");
    }
  };

  const handleAddOrder = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/mapOrder/`,
        newOrder
      );
      setOrders([...orders, response.data]);
      setNewOrder({ name: "", orderId: "", awsNumber: "" });
      setMessage("Order added successfully!");
      console.log("Order added successfully!")
    } catch (error) {
      console.error("Error adding order:", error);
      setMessage("Failed to add order. Please try again.");
    }
  };

  const handleUpdateOrder = async (id) => {
    const orderToUpdate = orders.find((order) => order.id === id);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/mapOrder/${id}`, orderToUpdate);
      fetchOrders(); // Refresh orders
      setMessage("Order updated successfully!");
    } catch (error) {
      console.error("Error updating order:", error);
      setMessage("Failed to update the order.");
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/mapOrder/${id}`);
      setOrders(orders.filter((order) => order.id !== id));
      setMessage("Order deleted successfully.");
    } catch (error) {
      console.error("Error deleting order:", error);
      setMessage("Failed to delete the order.");
    }
  };

  // const handleCheckAwsNumber = async (id) => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/mapOrder/${id}`);
  //     const awsNumber = response.data;
  //     setMessage(awsNumber ? `AWS Number: ${awsNumber}` : "Order not confirmed yet. We will notify you once confirmed.");
  //   } catch (error) {
  //     console.error("Error fetching AWS number:", error);
  //     setMessage("Failed to retrieve AWS number.");
  //   }
  // };

  return (
    <div className="container1">
      <h2>Order Management</h2>
      {message && <p className="message">{message}</p>} {/* Display message */}

      <h3>Add New Order</h3>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          placeholder="Name"
          value={newOrder.name}
          onChange={(e) => setNewOrder({ ...newOrder, name: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Order ID</label>
        <input
          type="text"
          placeholder="Order ID"
          value={newOrder.orderId}
          onChange={(e) => setNewOrder({ ...newOrder, orderId: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>AWS Number</label>
        <input
          type="text"
          placeholder="AWS Number"
          value={newOrder.awsNumber}
          onChange={(e) => setNewOrder({ ...newOrder, awsNumber: e.target.value })}
        />
      </div>
      <button onClick={handleAddOrder}>Add Order</button>

      <h3>Existing Orders</h3>
      <table className="order-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Order ID</th>
            <th>AWS Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>
                <input
                  type="text"
                  value={order.name}
                  onChange={(e) => {
                    const updatedOrders = orders.map((o) =>
                      o.id === order.id ? { ...o, name: e.target.value } : o
                    );
                    setOrders(updatedOrders);
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={order.orderId}
                  onChange={(e) => {
                    const updatedOrders = orders.map((o) =>
                      o.id === order.id ? { ...o, orderId: e.target.value } : o
                    );
                    setOrders(updatedOrders);
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={order.awsNumber}
                  onChange={(e) => {
                    const updatedOrders = orders.map((o) =>
                      o.id === order.id ? { ...o, awsNumber: e.target.value } : o
                    );
                    setOrders(updatedOrders);
                  }}
                />
              </td>
              <td>
                <button onClick={() => handleUpdateOrder(order.id)}>Update</button>
                <button onClick={() => handleDeleteOrder(order.id)}>Delete</button>
                {/* <button onClick={() => handleCheckAwsNumber(order.id)}>Check AWS Number</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
