import React, { useEffect, useState } from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { CiLocationOn } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6"; // Changed the import to FaMinus from the "fa6" package
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const [hide, setHide] = useState(false);
  const handleHide = () => {
    setHide(!hide);
  };

  const [hideParagraph1, setHideParagraph1] = useState(false);
  const [hideParagraph2, setHideParagraph2] = useState(false);
  const [hideParagraph3, setHideParagraph3] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const toggleParagraph1 = () => {
    setHideParagraph1(!hideParagraph1);
  };

  const toggleParagraph2 = () => {
    setHideParagraph2(!hideParagraph2);
  };

  const toggleParagraph3 = () => {
    setHideParagraph3(!hideParagraph3);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MDBFooter
      style={{ backgroundColor: "#CF7D08" }}
      className="text-center text-xxl text-muted"
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span className="footer-top-text">
            Get connected with us on social networks:
          </span>
        </div>

        <div className="link">
          <a
            href="https://www.google.com"
            className="me-4"
            target="_blank"
            rel="noopener noreferrer"
          ></a>
          <a
            href="https://www.instagram.com/themilletodyssey?igsh=YXI0a3hlNDZ0OXVk"
            className="me-4"
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
      </section>

      <section className="Footerdescription section-80">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="5" lg="4" xl="3" className="mx-auto mb-4 ">
              <img
                src="https://themilletodyssey.com/static/media/logo.7b1d941de5a9f4f06cba.png"
                style={{ marginLeft: "auto", marginRight: "60px" }}
                height={120}
                className="mb-4"
              />

              <div className="d-flex align-items-center">
                <CiLocationOn
                  style={{
                    marginRight: "10px",
                    marginTop: "-5px",
                    marginLeft: "-0px",
                  }}
                  size="5.1em"
                  color="#fff"
                />
                <p className="mb-0">
                  T-73, 3rd Floor, Singh Sabha Marg, Block B, Baljit Nagar,
                  Patel Nagar, New Delhi, 110008
                </p>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ marginTop: "20px" }}
              >
                <MdEmail
                  style={{ marginRight: "10px", marginTop: "-14px" }}
                  size="1.9em"
                  color="#fff"
                />
                <p>themilletodyssey@gmail.com</p>
              </div>

              <div
                className="d-flex align-items-center mb-4"
                style={{ marginTop: "10px" }}
              >
                <IoCallOutline
                  style={{ marginRight: "10px", marginTop: "-12px" }}
                  size="1.7em"
                  color="#fff"
                />
                <p>+91-8051068546</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mx-5">
                <FaFacebookF
                  style={{ marginRight: "20px", marginLeft: "-50px" }}
                  size="1.9em"
                  color="#fff"
                />
                <FaInstagram
                  style={{ marginRight: "20px", marginLeft: "-10px" }}
                  size="1.9em"
                  color="#fff"
                />
                <FaLinkedin
                  style={{ marginRight: "20px", marginLeft: "-10px" }}
                  size="1.9em"
                  color="#fff"
                />
                <FaYoutube
                  style={{ marginRight: "20px", marginLeft: "-10px" }}
                  size="1.9em"
                  color="#fff"
                />
              </div>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className={`text-uppercase fw-bold mb-4 ${
                  isSmallScreen
                    ? "text-start mx-4 d-flex justify-content-between align-items-center"
                    : ""
                }`}
                onClick={isSmallScreen ? toggleParagraph1 : null}
              >
                Useful links
                {isSmallScreen &&
                  (hideParagraph1 ? (
                    <FaPlus size="1.25em" color="#fff" className="mx-4" />
                  ) : (
                    <FaMinus size="1.25em" color="#fff" className="mx-4" />
                  ))}
              </h6>
              {(!isSmallScreen || !hideParagraph1) && (
                <>
                  <p>
                    <Link to="/" className="links">
                      Home
                    </Link>
                  </p>
                  <p>
                    <Link to="/aboutus" className="links">
                      About
                    </Link>
                  </p>
                  <p>
                  <a
                      href="https://drive.google.com/file/d/1yYaBUfM0V9XiXN2CCMsqAvT6ZS9yaoIf/view?usp=sharing"
                      className="links"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     Services 
                    </a>
                  </p>
                  <p>
                    <Link to="/contact-us" className="links">
                      Contact
                    </Link>
                  </p>
                </>
              )}
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className={`text-uppercase fw-bold mb-4 ${
                  isSmallScreen
                    ? "text-start mx-4 d-flex justify-content-between align-items-center"
                    : ""
                }`}
                onClick={isSmallScreen ? toggleParagraph2 : null}
              >
                Products
                {isSmallScreen &&
                  (hideParagraph2 ? (
                    <FaPlus size="1.25em" color="#fff" className="mx-4" />
                  ) : (
                    <FaMinus size="1.25em" color="#fff" className="mx-4" />
                  ))}
              </h6>
              {(!isSmallScreen || !hideParagraph2) && (
                <>
                  <p>
                    <Link to="/shop" className="links">
                      Artisan Cookies Collection
                    </Link>
                  </p>
                  <p>
                    <Link to="/shop" className="links">
                      Desi Protein - Rich Treats
                    </Link>
                  </p>
                  <p>
                    <Link to="/shop" className="links">
                      Premium Seed Mix Medley
                    </Link>
                  </p>
                  <p>
                    <Link to="/shop" className="links">
                      Customisation
                    </Link>
                  </p>
                </>
              )}
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className={`text-uppercase fw-bold mb-4 ${
                  isSmallScreen
                    ? "text-start mx-4 d-flex justify-content-between align-items-center"
                    : ""
                }`}
                onClick={isSmallScreen ? toggleParagraph3 : null}
              >
                Information
                {isSmallScreen &&
                  (hideParagraph3 ? (
                    <FaPlus size="1.25em" color="#fff" className="mx-4" />
                  ) : (
                    <FaMinus size="1.25em" color="#fff" className="mx-4" />
                  ))}
              </h6>
              {(!isSmallScreen || !hideParagraph3) && (
                <>
                  <p>
                    <a
                      href="https://drive.google.com/file/d/1yf5PiP6yGAivV7-qKojNe7Et9mOphSdV/view?usp=drive_link"
                      className="links"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p>
                  
                  <p>
                    <a
                      href="https://drive.google.com/file/d/17X9-PtsnbvwFPKOJxK60gQ5Q-v84FHW1/view?usp=drive_link"
                      className="links"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Shipping Policy
                    </a>
                  </p>
                  
                  <p>
                    <a
                      href="https://drive.google.com/file/d/15CB_xx6uiatOHlNmrQHpreF5cQ0F9emB/view?usp=drive_link"
                      className="links"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://drive.google.com/file/d/1BFi1VZPnOx6mCT-VrSf20cYO7VAUe6Uw/view?usp=drive_link"
                      className="links"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Refund & Cancellation Policy
                    </a>
                  </p>
                </>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className="text-center p-4 copy-right">
        © 2024 Copyright:
        <Link className="text-reset fw-bold" to="/">
          TheMilletOdyssey.com
        </Link>
      </div>
    </MDBFooter>
  );
}
