import React, { useState, useEffect } from "react";
import axios from "axios";
import './TrackShipment.css';

const TrackShipment = ({ orderId }) => {
  const [shipmentData, setShipmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [awsNumber, setAwsNumber] = useState(null);

  useEffect(() => {
    const fetchAwsNumber = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/mapOrder/${orderId}`);
        const awsNum = response.data;
        setAwsNumber(awsNum);

        if (!awsNum) {
          setError("The order has not been confirmed yet. We will inform you once confirmed.");
        }
      } catch (err) {
        console.error("Error fetching AWS number:", err);
        setError("The order has not been confirmed yet. We will inform you once confirmed");
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchAwsNumber();
    }
  }, [orderId]);

  useEffect(() => {
    const fetchShipmentData = async () => {
      if (!awsNumber) return;

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          // `https://thingproxy.freeboard.io/fetch/https://track.delhivery.com/api/v1/packages/json/?waybill=${awsNumber}`,
           `https://track.delhivery.com/api/v1/packages/json/?waybill=${awsNumber}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token 497be93ecebccb78e0eeeb52be3876a0cdf54459",
            }
          }
        );

        console.log("response",response);
        // console.log("day",expectedDeliveryDate)
        const shipmentData = response.data.ShipmentData.map(item => {
          const shipment = item.Shipment;

          return {
            pickUpDate: shipment.PickUpDate,
            destination: shipment.Destination,
            status: shipment.Status.Status,
            statusLocation: shipment.Status.StatusLocation,
            statusDateTime: shipment.Status.StatusDateTime,
            scannedLocation: shipment.Scans ? shipment.Scans.map(scan => scan.ScanDetail.ScannedLocation) : [],
            instructions: shipment.Scans ? shipment.Scans.map(scan => scan.ScanDetail.Instructions) : [],
            invoiceAmount: shipment.InvoiceAmount,
            expectedDeliveryDate: shipment.ExpectedDeliveryDate,
            senderName: shipment.SenderName,
            awb: shipment.AWB,
            quantity: shipment.Quantity,
            consignee: {
              name: shipment.Consignee.Name,
              city: shipment.Consignee.City,
              state: shipment.Consignee.State,
              country: shipment.Consignee.Country,
              pinCode: shipment.Consignee.PinCode,
              telephone1: shipment.Consignee.Telephone1,
              telephone2: shipment.Consignee.Telephone2,
            }
          };
        });

        setShipmentData(shipmentData);
      } catch (error) {
        console.error('Error fetching shipment data:', error);
        setError("The order has not been confirmed yet. We will inform you once confirmed");
      } finally {
        setLoading(false);
      }
    };

    fetchShipmentData();
  }, [awsNumber]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!shipmentData || shipmentData.length === 0) return <div>No shipment data found.</div>;

  const shipment = shipmentData[0]; // Assuming we only need the first shipment
  const { Scans = [], status, statusLocation, statusDateTime, expectedDeliveryDate } = shipment; // Default Scans to an empty array

  // Define the stages for the progress tracker
  const stages = [
    { label: "Order Received", statusKey: "Manifested" },
    { label: "Ready To Ship", statusKey: "Ready To Ship" },
    { label: "Scheduled for Pickup", statusKey: "Scheduled for Pickup" },
    { label: "In Transit", statusKey: "In Transit" },
    { label: "Out for Delivery", statusKey: "Out for Delivery" },
    { label: "Delivered", statusKey: "Delivered" }
  ];

  // Determine the current stage index based on the actual status of the shipment
  let currentStageIndex = stages.findIndex(
    (stage) => stage.statusKey === status
  );

  // Handle case when no matching status is found (default to the first stage)
  if (currentStageIndex === -1) currentStageIndex = 0;

  // Map the scans to corresponding stages and include respective dates dynamically
  const stageDates = stages.map((stage) => {
    const scan = Scans.find((s) => s.Status === stage.statusKey);
    return scan ? scan.StatusDateTime : null; // use the date from scans if available
  });

  // Format date and time for display
  const formatDate = (dateString) =>
    new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    

  return (
    <div className="tracking-page">
      <h1 className="title">Track Your Shipment</h1>

      {/* Progress Tracker */}
      <div className="progress-tracker mb-6">
        {stages.map((stage, index) => (
          <div
            key={index}
            className={`tracker-step ${index <=currentStageIndex ? 'completed' : ''} ${
              index === currentStageIndex ? 'current' : ''
            }`}
          >
            <div className="step-icon">
              {index <= currentStageIndex ? "✓" : index + 1}
            </div>
            <div className="step-label">
              <h4>{stage.label}</h4>
              {stageDates[index] && (
                <p>{formatDate(stageDates[index])}</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Current Shipment Status and Expected Delivery Date */}
     {/* Current Shipment Status and Expected Delivery Date */}
{shipment && (
  <div className="current-status border-t pt-4">
    <h3>Current Status: {status}</h3>
    {status !== "Delivered" && (
      <h2>
        <strong>Expected Delivery Date:</strong>{" "}
        {expectedDeliveryDate ? formatDate(expectedDeliveryDate) : "Not available"}
      </h2>
    )}
    
  </div>
)}

    </div>
  );
};

export default TrackShipment;
